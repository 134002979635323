<template>
  <div
    class="p-relative bg-image ratio"
    @click="$emit('click', $event)"
    @blur="$emit('blur', $event)"
    @focus="$emit('focus', $event)"
    :style="{ paddingBottom: ratio ? padding + '%' : '' }"
  >
    <div class="p-absolute fill-parent content-center" v-if="!loaded || !src">
      <span class="sticker bg-light" :class="{ 'sticker-sm': small }"
        ><i class="i-youtube"></i
      ></span>
    </div>

    <iframe
      v-if="src"
      class="p-absolute fill-parent"
      id="ytplayer"
      type="text/html"
      width="640"
      height="360"
      :src="'https://www.youtube.com/embed/' + src"
      frameborder="0"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      required: true
    },
    small: Boolean,
    ratio: String
  },
  computed: {
    padding() {
      let r = this.ratio ? this.ratio.toString().split("x") : [4, 3];

      return (r[1] / r[0]) * 100;
    }
  },
  data() {
    return {
      loaded: false
    };
  },
  watch: {
    src: function(newVal) {
      let img = new Image();
      img.src = newVal;

      this.loaded = false;

      img.onload = () => {
        this.loaded = true;
        this.$emit("loaded", newVal);
      };
    }
  },
  mounted() {
    let img = new Image();
    img.src = this.src;

    img.onload = () => {
      this.loaded = true;
      this.$emit("loaded", this.src);
    };
  }
};
</script>
